import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import Grid from "styled-components-grid";
import { Margin, p, pl, pr, mb } from "styled-components-spacing";
import Layout from "../components/Layout";
import Header from "../components/GatsbyHeader";
import Heading from "../components/Heading";
import Markdown from "../components/Markdown";
import Section from "../components/Section";
import Content, { HTMLContent } from "../components/Content";
import tickIcon from "../img/tick.svg";

import {
  Factory,
  Home as HomeIcon,
  StoreFront,
  Wall,
  Bolt,
  Add,
  Buoy,
  Clipboard,
  Tree
} from "../icons";

const ServicesGrid = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;
  flex-wrap: wrap;

  @supports (display: grid) {
    display: grid;
    grid-gap: 2rem;

    ${breakpoint("tablet")`
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 3rem;
    `}

    ${breakpoint("desktop")`
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 4rem;
    `}
  }
`;

const Li = styled.li`
  display: inline-block;
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  ${mb(4)};

  ${breakpoint("tablet")`
    flex-basis: 50%;
    max-width: 50%;
    
    &:nth-child(2n + 1) {
      ${pr(3)};
    }
  `}

  ${breakpoint("desktop")`
    flex-basis: 33.33%;
    max-width: 33.33%;
    ${pr(3)};

    &:nth-child(3n + 3) {
      ${pr(0)};
    }
  `}

  ${breakpoint("tablet")`
    display: block;
  `}

  @supports (display: grid) {
    max-width: none;
    ${mb(0)};
    ${pr(0)};
    padding-right: 0 !important;
  }
`;

const Service = props => (
  <Li>
    <Grid wrap={{ mobile: false, tablet: true }}>
      <Grid.Unit size={{ mobile: "min", tablet: 1 }}>
        <Margin
          bottom={{ mobile: 0, tablet: 2 }}
          right={{ mobile: 3, tablet: 0 }}
        >
          {props.icon}
        </Margin>
      </Grid.Unit>
      <Grid.Unit size={{ mobile: "max", tablet: 1 }}>
        <h6 style={{ lineHeight: "1.5" }}>{props.name}</h6>
      </Grid.Unit>
    </Grid>
  </Li>
);

const MakeListsFeatured = styled.div`
  ul {
    ${p(4)};
    background-color: #007fb0;
    color: white;
    list-style: none;
  }

  li {
    ${mb(2)};
    ${pl(4)};
    background-image: url(${tickIcon});
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: 0 8px;
  }
`;

export const HomePageTemplate = ({
  title,
  subtext,
  introduction,
  image,
  content,
  contentComponent,
  servicesTitle,
  newResidences,
  alterations,
  commercial,
  industrial,
  retainingWall,
  concretePool,
  stormWater,
  erosion,
  inspections
}) => {
  const PageContent = contentComponent || Content;

  return (
    <React.Fragment>
      <Header
        imageData={
          image && !!image.childImageSharp ? image.childImageSharp.fluid : image
        }
        title={title}
        subtext={subtext}
        introduction={introduction}
        size="big"
      />
      <section>
        <Section as="div">
          {introduction && (
            <Heading size={4} style={{ lineHeight: "1.25" }}>
              <Markdown content={introduction} />
            </Heading>
          )}
          <MakeListsFeatured>
            <PageContent className="content" content={content} />
          </MakeListsFeatured>
        </Section>
        <Section style={{ backgroundColor: "#007fb0", color: "#fff" }} as="div">
          <Margin bottom={{ mobile: 4, desktop: 5 }}>
            <Heading size={2}>{servicesTitle}</Heading>
          </Margin>
          <ServicesGrid>
            <Service name={newResidences} icon={<HomeIcon />} />
            <Service name={alterations} icon={<Add />} />
            <Service name={commercial} icon={<StoreFront />} />
            <Service name={industrial} icon={<Factory />} />
            <Service name={retainingWall} icon={<Wall />} />
            <Service name={concretePool} icon={<Buoy />} />
            <Service name={stormWater} icon={<Bolt />} />
            <Service name={erosion} icon={<Tree />} />
            <Service name={inspections} icon={<Clipboard />} />
          </ServicesGrid>
        </Section>
      </section>
    </React.Fragment>
  );
};

HomePageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  subtext: PropTypes.string,
  introduction: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  servicesTitle: PropTypes.string
};

HomePageTemplate.defaultProps = {
  servicesTitle: "Our Services"
};

const Home = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <HomePageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        subtext={post.frontmatter.subtext}
        introduction={post.frontmatter.introduction}
        image={post.frontmatter.image}
        content={post.html}
        servicesTitle={post.frontmatter.servicesTitle}
        newResidences={post.frontmatter.newResidences}
        alterations={post.frontmatter.alterations}
        commercial={post.frontmatter.commercial}
        industrial={post.frontmatter.industrial}
        retainingWall={post.frontmatter.retainingWall}
        concretePool={post.frontmatter.concretePool}
        stormWater={post.frontmatter.stormWater}
        erosion={post.frontmatter.erosion}
        inspections={post.frontmatter.inspections}
      />
    </Layout>
  );
};

Home.propTypes = {
  data: PropTypes.object.isRequired
};

export default Home;

export const HomeQuery = graphql`
  query Home($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        subtext
        introduction
        image {
          childImageSharp {
            fluid(maxWidth: 1600, quality: 60) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        servicesTitle
        newResidences
        alterations
        commercial
        industrial
        retainingWall
        concretePool
        stormWater
        erosion
        inspections
      }
    }
  }
`;
