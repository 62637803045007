import React from "react";
import PropTypes from "prop-types";

const Icon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    width={props.width}
    height={props.width}
  >
    {props.children}
  </svg>
);

Icon.propTypes = {
  width: PropTypes.number
};

Icon.defaultProps = {
  width: 24,
  fill: "white"
};

export const Factory = props => (
  <Icon width={props.width}>
    <path
      d="M10.5 20H0V7l5 3.33V7l5 3.33V7l5 3.33V0h5v20h-9.5z"
      fill={props.fill}
    />
  </Icon>
);

Factory.defaultProps = Icon.defaultProps;

export const Home = props => (
  <Icon width={props.width}>
    <path fill={props.fill} d="M8 20H3V10H0L10 0l10 10h-3v10h-5v-6H8v6z" />
  </Icon>
);

Home.defaultProps = Icon.defaultProps;

export const StoreFront = props => (
  <Icon width={props.width}>
    <path
      fill={props.fill}
      d="M18 9.87V20H2V9.87a4.25 4.25 0 0 0 3-.38V14h10V9.5a4.26 4.26 0 0 0 3 .37zM3 0h4l-.67 6.03A3.43 3.43 0 0 1 3 9C1.34 9 .42 7.73.95 6.15L3 0zm5 0h4l.7 6.3c.17 1.5-.91 2.7-2.42 2.7h-.56A2.38 2.38 0 0 1 7.3 6.3L8 0zm5 0h4l2.05 6.15C19.58 7.73 18.65 9 17 9a3.42 3.42 0 0 1-3.33-2.97L13 0z"
    />
  </Icon>
);
StoreFront.defaultProps = Icon.defaultProps;

export const Wall = props => (
  <Icon width={props.width}>
    <path
      fill={props.fill}
      d="M12 4H8v12h4V4zm2 0v12h4V4h-4zM6 4H2v12h4V4zM0 2h20v16H0V2z"
    />
  </Icon>
);
Wall.defaultProps = Icon.defaultProps;

export const Bolt = props => (
  <Icon width={props.width}>
    <path fill={props.fill} d="M13 8V0L8.11 5.87 3 12h4v8L17 8h-4z" />
  </Icon>
);
Bolt.defaultProps = Icon.defaultProps;

export const Add = props => (
  <Icon width={props.width}>
    <path
      fill={props.fill}
      d="M11 9V5H9v4H5v2h4v4h2v-4h4V9h-4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20z"
    />
  </Icon>
);
Add.defaultProps = Icon.defaultProps;

export const Buoy = props => (
  <Icon width={props.width}>
    <path
      fill={props.fill}
      d="M17.16 6.42a8.03 8.03 0 0 0-3.58-3.58l-1.34 2.69a5.02 5.02 0 0 1 2.23 2.23l2.69-1.34zm0 7.16l-2.69-1.34a5.02 5.02 0 0 1-2.23 2.23l1.34 2.69a8.03 8.03 0 0 0 3.58-3.58zM6.42 2.84a8.03 8.03 0 0 0-3.58 3.58l2.69 1.34a5.02 5.02 0 0 1 2.23-2.23L6.42 2.84zM2.84 13.58a8.03 8.03 0 0 0 3.58 3.58l1.34-2.69a5.02 5.02 0 0 1-2.23-2.23l-2.69 1.34zM10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-7a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
    />
  </Icon>
);
Buoy.defaultProps = Icon.defaultProps;

export const Clipboard = props => (
  <Icon width={props.width}>
    <path
      fill={props.fill}
      d="M7.03 2.6a3 3 0 0 1 5.94 0L15 3v1h1a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2h1V3l2.03-.4zM5 6H4v12h12V6h-1v1H5V6zm5-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
    />
  </Icon>
);
Clipboard.defaultProps = Icon.defaultProps;

export const Tree = props => (
  <Icon width={props.width}>
    <path
      fill={props.fill}
      d="M5.33 12.77A4 4 0 1 1 3 5.13V5a4 4 0 0 1 5.71-3.62 3.5 3.5 0 0 1 6.26 1.66 2.5 2.5 0 0 1 2 2.08 4 4 0 1 1-2.7 7.49A5.02 5.02 0 0 1 12 14.58V18l2 1v1H6v-1l2-1v-3l-2.67-2.23zM5 10l3 3v-3H5z"
    />
  </Icon>
);
Tree.defaultProps = Icon.defaultProps;

export default Factory;
